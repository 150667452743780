<h1 class="visit-cost">Visit Cost</h1>
<content-loader *ngIf="CostLoading" viewBox="0 0 400 30" foregroundColor="#D1D5DB">
  <svg:rect x="0" y="0" rx="3" ry="3" width="140" height="24" />
</content-loader>

<p *ngIf="!CostLoading">{{VisitCost | currency}}</p>
<p>Important message for members who leveraged Blue KC Virtual Care February 21, 2024-June 3, 2024, during the Change Healthcare outage!</p>
<p>Beginning January 20, 2025, Amwell, provider partner for Blue KC Virtual Care, will begin billing members who received care between February 21, 2024 - June 3, 2024. If you had a Blue KC Virtual Care visit during those dates, your member cost share was not collected at time of service.  Amwell recently submitted claims to Blue KC for virtual care services received during this period. Blue KC has processed the claims according to your Blue KC benefits, and you may have received an EOB listing your member responsibility.</p>
<p>Members who will be charged for services: If you had a visit during this timeframe, and your plan benefits require copay or other cost-sharing, but you were charged $0, Amwell will charge the balance due to the credit card on file if the balance is under $250. If your balance due is over $250, Amwell will mail a paper statement. Blue KC members may have previously received an EOB listing the charges owed.</p>
<p>Members who will receive a refund for services: If you had a visit during this timeframe, and your plan benefits do not require payment, but you were prompted to pay, Amwell will issue a refund to the credit card on file.</p>
<p>You can verify these transactions via MyBlueKC.com under Virtual Care "visit history".</p>
<p>We apologize for the inconvenience. If you have any questions, specific to a recent charge for a Blue KC Virtual Visit between February 21 2024 and June 3, 2024 please call Amwell Customer Support at 1-800-317-5656.</p>

<ng-container *ngIf="this.VisitCost > 0 || CostLoading;else noCostTemplate">


  <article *ngIf="PaymentEntered">
    <h1>Payment Method</h1>

    <div *ngIf="PaymentMethodLoading">
      <content-loader viewBox="0 0 400 30" foregroundColor="#D1D5DB">
        <svg:rect x="0" y="0" rx="3" ry="3" width="200" height="24" />
      </content-loader>
      <button [disabled]="true" class="primary-button">Start Visit</button>
    </div>

    <div *ngIf="!PaymentMethodLoading">

      <app-payment-current [PaymentMethod]="SelectedPaymentMethod"></app-payment-current>

      <button *ngIf="!CardExpired" class="primary-button" (click)="NextStep()" title="Confirm">Confirm Payment & Start
        Visit</button>

      <button (click)="ConfirmCardChange()" class="{{CardExpired ? 'blue': 'secondary-button'}}"
        title="Update">{{CardExpired ?
        'Update Payment Method'
        : 'Change Payment Method'}}</button>

    </div>
  </article>

  <p class="error-text" *ngIf="ShowDeclinationError">Your card has been declined. Please verify your card information to
    continue.</p>

  <article *ngIf="!PaymentEntered && !PaymentMethodLoading" class="payment-info">
    <app-payment-info (SelectedPaymentMethod)="NewPaymentEntered($event)" (Oopsie)="RegisterOopsie()" (CardExpired)="CardExpired">
    </app-payment-info>
  </article>
</ng-container>


<ng-template #noCostTemplate>
  <ng-container *ngIf="VisitCost == 0 && IsMaMember">
    <!--Covid messages-->
    <p>At this time, Blue KC is not charging for Virtual Care visits during the current public health
      emergency.</p>
  </ng-container>
  <!--Zero cost visit-->
  <ng-container *ngIf="VisitCost == 0 && !IsMaMember">
    <h1>Payment Method</h1>
    <p>No payment is necessary for this visit.</p>
  </ng-container>
  <button  class="primary-button" [disabled]="CostLoading || CostFailed" (click)="NextStep()" title="Confirm">Confirm & Start
    Visit</button>
</ng-template>

<button (click)="goBack()" class="link-button back" title="Go Back">Go Back</button>
